<template>
    <div>
        <Modal
            v-model="showModal"
            draggable
            sticky
            title="Import Event"
            no-footer
            :closable="closable"
            height="1000"
            class="createRequest"
            :before-close="handleReset"
        >
            <Form
                ref="importEventForm"
                :model="event"
                :loading="isLoading"
                label-position="left"
                class="create-time-dispute-wrapper"
            >
                <Row>
                    <Col span="8">
                        <FormItem label="Csv File">
                            <Upload action="//jsonplaceholder.typicode.com/posts/" :before-upload="testUpload">
                                <Button icon="ios-cloud-upload-outline">Upload files</Button>
                            </Upload>
                            <div v-if="file !== null">
                                Upload file: {{ file.name }}
                                <Button type="text" @click="deleteFile" :loading="isLoading">x</Button>
                            </div>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <div>
                <Button class="pt-btn-3" :disabled="disableUpload" :loading="isLoading" @click="handleCsvUpload">
                    SUBMIT
                </Button>
                <Button class="pt-btn-2" style="margin-right: 10px" @click="handleCancel"> CANCEL </Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { uploadCsvEvents } from '@/services/calendar-events.services';

export default {
    components: {},
    props: {
        isAdmin: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            leaves: {
                al: 0,
                sl: 0,
                vl: 0,
            },
            isReadOnly: false,
            disableLeaveType: false,
            userListDisabled: false,
            creditDetails: null,
            selectedUser: null,
            attendanceLogData: null,
            isLoading: false,
            options: null,
            closable: false,
            event: {
                type: null,
                date: null,
                name: '',
            },
            file: null,
            disableUpload: true,
            isDisabled: false,
            showModal: false,
            userId: null,
        };
    },
    created() {
        this.options = {
            disabledDate(date) {
                console.log('date: ', date);
                return Date.now();
            },
        };
    },
    methods: {
        async handleCsvUpload() {
            try {
                this.isLoading = true;
                this.disableUpload = true;
                await uploadCsvEvents(this.file);
                this.$Message.success('uploaded csv succesfully', 5);
                this.$emit('create');
                this.handleCancel();
            } catch (err) {
                this.$Message.error(`unable to upload: ${err.data.message}`, 5);
            } finally {
                this.isLoading = false;
                this.disableUpload = false;
            }
        },
        deleteFile() {
            this.file = null;
        },
        testUpload(file) {
            this.file = file;
            this.disableUpload = false;
            return false;
        },
        openModal() {
            // console.log(data, fromShiftTable);
            // this.selectedUser = { _id: null };
            // this.isLoading = false;
            this.showModal = true;
            this.forEdit = false;
        },

        handleConfirm() {
            this.showModal = false;
        },
        handleReset() {
            this.$refs.calendarEventForm.resetFields();
        },
        handleCancel() {
            this.file = null;
            this.disableUpload = true;
            this.showModal = false;
        },
    },
};
</script>
<style lang="scss">
form.ivu-form.ivu-form-label-left.ivu-form-inline {
    border: unset;
    .ivu-form-item {
        display: inline-block !important;
        margin-right: 10px !important;
        vertical-align: top !important;
    }

    .ivu-form-item-content {
        margin-left: 0 !important;
        display: block !important;
    }
}
.createRequest .ivu-modal-body {
    padding: 16px;
}
.createRequest .ivu-modal-footer {
    display: none;
}
.createRequest .ivu-spin-main {
    justify-content: center;
    display: flex;
}
.create-time-dispute-wrapper {
    .ivu-form-item {
        display: inline-block !important;
        margin-right: 10px !important;
        vertical-align: top !important;
    }

    .ivu-form-item-content {
        margin-left: 0 !important;
        display: block !important;
    }
}

@media screen and (max-width: 767px) {
    .create-time-dispute-wrapper {
        .ivu-form-item {
            margin: 20px 5px;

            .ivu-form-item-content {
                margin-left: 0 !important;
                display: block !important;
            }
        }
    }
}
</style>
