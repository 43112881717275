<template>
    <div>
        <Modal
            v-model="showModal"
            draggable
            sticky
            title="Add Calendar Event"
            no-footer
            :closable="closable"
            height="1000"
            class="createRequest"
            :before-close="handleReset"
        >
            <Form
                ref="calendarEventForm"
                :model="event"
                :loading="isLoading"
                label-position="left"
                class="create-time-dispute-wrapper"
            >
                <Row>
                    <Col span="8">
                        <FormItem label="Holiday Name">
                            <Input
                                v-model="event.name"
                                type="text"
                                min="1"
                                max="5"
                                placeholder="Enter something..."
                                style="width: 200px"
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                        <FormItem label="Holiday Date">
                            <DatePicker
                                v-model="event.date"
                                type="date"
                                format="yyyy-MM-dd"
                                placeholder="Select month"
                                style="width: 350px"
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormItem label="Holiday Type" prop="requestedStartTime"> </FormItem>
                        <v-radio-group v-model="event.type" row>
                            <v-radio label="Regular" value="Regular-Holiday"> </v-radio>
                            <v-radio label="Special Working" value="Special-Working-Holiday"></v-radio>
                            <v-radio label="Special Non-Working" value="Special-Non-Working-Holiday"></v-radio>
                        </v-radio-group>
                    </Col>
                </Row>
            </Form>
            <div>
                <Button v-if="!forEdit" class="pt-btn-3" :loading="isLoading" @click="handleAddCalendarEvent">
                    SUBMIT
                </Button>
                <Button
                    v-if="forEdit"
                    class="pt-btn-3"
                    :disabled="isReadOnly"
                    :loading="isLoading"
                    @click="processEditEvent"
                >
                    Update Event
                </Button>

                <Button class="pt-btn-2" style="margin-right: 10px" @click="handleCancel"> CANCEL </Button>
            </div>
        </Modal>
    </div>
</template>
<script>
// import { getLeavesInfo } from '@/services/leave.services';

import { submitEvent, updateEvent } from '@/services/calendar-events.services';
import { parseInt } from 'lodash';
import moment from 'moment/moment';

export default {
    components: {},
    props: {
        isAdmin: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            leaves: {
                al: 0,
                sl: 0,
                vl: 0,
            },
            isReadOnly: false,
            disableLeaveType: false,
            userListDisabled: false,
            creditDetails: null,
            selectedUser: null,
            attendanceLogData: null,
            isLoading: false,
            options: null,
            closable: false,
            event: {
                type: null,
                date: null,
                name: '',
            },
            forEdit: false,
            isDisabled: false,
            showModal: false,
            userId: null,
        };
    },
    created() {
        this.options = {
            disabledDate(date) {
                console.log('date: ', date);
                return Date.now();
            },
        };
    },
    methods: {
        async handleAddCalendarEvent() {
            this.$refs.calendarEventForm.validate((valid) => {
                if (valid) {
                    this.isLoading = true;
                    if (!this.forEdit) {
                        this.processEvent();
                    }
                } else {
                    this.$Message.error('Fill in the required fields');
                }
            });
        },
        async processEvent() {
            try {
                await submitEvent({
                    ...this.event,
                    date: moment(this.event.date).format('yyyy-MM-DD'),
                    content: this.event.name,
                });
                this.$Message.success('Added Calendar Event Successfully');
                this.$emit('create');
                this.showModal = false;
            } catch (err) {
                this.$Message.error(`Error Submitting Calendar Event: ${err.data.message}`, 10);
            } finally {
                this.isLoading = false;
            }
        },
        async processEditEvent() {
            try {
                await updateEvent({
                    ...this.event,
                    date: moment(this.event.date).format('yyyy-MM-DD'),
                    content: this.event.name,
                });
                this.isLoading = false;
                this.$Message.success('Updated Calendar Event Successfully');
                this.$emit('create');
                this.showModal = false;
            } catch (err) {
                this.$Message.error(`Error Updating Calendar Event: ${err.data.message}`, 10);
            } finally {
                this.isLoading = false;
            }
        },
        async adminGrantLeaveCredits() {
            if (!this.selectedUser) {
                this.$Message.error('Select Employee');
            }
            if (!this.credit.type) {
                this.$Message.error('Input Leave Type');
            } else if (this.credit.credit_approved < 1) {
                this.$Message.error('Update Credit requested');
            } else {
                this.isLoading = true;

                try {
                    const data = {
                        type: this.credit.type,
                        credit_approved: parseInt(this.credit.credit_approved),
                        user: this.selectedUser._id,
                    };
                    console.log('data: ', data);
                    // await adminDoGrantEmployeeCredit(data);
                    this.$Message.success({ content: 'Credit Added', duration: 3 });
                    this.$emit('create');
                    this.handleCancel();
                } catch (err) {
                    console.log('err: ', err);
                    this.$Message.error({ content: err.data.message, duration: 3 });
                } finally {
                    this.isLoading = false;
                }
            }
        },

        openModal() {
            // console.log(data, fromShiftTable);
            // this.selectedUser = { _id: null };
            // this.isLoading = false;
            this.showModal = true;
            this.forEdit = false;
        },
        openModalForEditing(data) {
            console.log('openModalForEditing: ', data);
            this.event.name = data.content;
            this.event.type = data.type;
            this.event._id = data._id;
            this.event.date = data.date;
            this.showModal = true;
            this.forEdit = true;
        },

        handleConfirm() {
            this.showModal = false;
        },
        handleReset() {
            this.$refs.calendarEventForm.resetFields();
        },
    },
};
</script>
<style lang="scss">
form.ivu-form.ivu-form-label-left.ivu-form-inline {
    border: unset;
    .ivu-form-item {
        display: inline-block !important;
        margin-right: 10px !important;
        vertical-align: top !important;
    }

    .ivu-form-item-content {
        margin-left: 0 !important;
        display: block !important;
    }
}
.createRequest .ivu-modal-body {
    padding: 16px;
}
.createRequest .ivu-modal-footer {
    display: none;
}
.createRequest .ivu-spin-main {
    justify-content: center;
    display: flex;
}
.create-time-dispute-wrapper {
    .ivu-form-item {
        display: inline-block !important;
        margin-right: 10px !important;
        vertical-align: top !important;
    }

    .ivu-form-item-content {
        margin-left: 0 !important;
        display: block !important;
    }
}

@media screen and (max-width: 767px) {
    .create-time-dispute-wrapper {
        .ivu-form-item {
            margin: 20px 5px;

            .ivu-form-item-content {
                margin-left: 0 !important;
                display: block !important;
            }
        }
    }
}
</style>
