import { axiosInstance, getAuthorizationHeaders } from './base';

/*Employee Api Endpoints*/
const roleUrlString = localStorage.getItem('mainRole') === 'admin' ? `admin/calendar-event` : `/calendar-event`;
export function getCalendarEventList({ filter = {}, page = 1, limit = 1 }) {
    const urlString = `/get-paginated-list`;
    return axiosInstance.get(roleUrlString + urlString, {
        params: { filter, page, limit, sort: { date: 1 } },
        ...getAuthorizationHeaders(),
    });
}
export function getCalendarAttendanceList(date) {
    const urlString = `/get-calendar-attendance`;
    return axiosInstance.get(roleUrlString + urlString, {
        params: { date },
        ...getAuthorizationHeaders(),
    });
}
export function getCalendarBdaysAndAnniversaries(date) {
    const urlString = `/get-birthdays-and-anniversaries`;
    return axiosInstance.get(roleUrlString + urlString, {
        params: { date },
        ...getAuthorizationHeaders(),
    });
}

export function submitEvent(data) {
    const urlString = `/submit-event`;
    return axiosInstance.post(roleUrlString + urlString, data, getAuthorizationHeaders());
}

export function deleteEvent(data) {
    const urlString = `/${data._id}/delete-event`;
    return axiosInstance.delete(roleUrlString + urlString, getAuthorizationHeaders());
}
export function updateEvent(data) {
    const urlString = `/${data._id}/update-event`;
    return axiosInstance.put(roleUrlString + urlString, data, getAuthorizationHeaders());
}

export function uploadCsvEvents(data) {
    const urlString = `/import-csv`;
    const formData = new FormData()
    formData.append('file', data);
    return axiosInstance.post(roleUrlString + urlString, formData, {
        headers: { ...getAuthorizationHeaders().headers, 'Content-Type': 'multipart/form-data' },
    });
}