<template>
    <div>
        <Col class="d-flex-right">
            <Button class="pt-btn-3 d-flex-center text-center" style="margin: 10px" @click="openModal">
                <Icon type="md-add" size="20" /> Add Event
            </Button>
        </Col>
        <Col class="d-flex-right">
            <Button class="pt-btn-5 d-flex-center text-center" style="margin: 10px" @click="openImportModal">
                <Icon type="md-add" size="20" /> Import Csv
            </Button>
        </Col>
        <AdminCalendarEventList
            :requests-data-list="data"
            :total-pages="totalPages"
            :current-page="page"
            :total-per-page="limit"
            :total-count="totalCount"
            :year-selected="year"
            @on-page-change="changePage"
            @changeYear="changeYear"
            @edit-event="openModalForEditing"
            @on-delete-request="deleteEvent"
        ></AdminCalendarEventList>

        <ImportEvent ref="importEvent" @create="refresh"></ImportEvent>
        <AddCalendarEvent ref="calendarEventRequest" @create="refresh"></AddCalendarEvent>
        <ModalConfirmation ref="deleteEventModal" :message="deleteMessage" @ok="onDeleteEvent"></ModalConfirmation>
    </div>
</template>

<style scoped lang="scss"></style>

<script setup>
import AdminCalendarEventList from '@/components/Calendar/admin/AdminCalendarEventList.vue';
import { getCalendarEventList, deleteEvent } from '@/services/calendar-events.services';
import AddCalendarEvent from '@/components/Calendar/admin/AddCalendarEvent.vue';
import ModalConfirmation from '@/components/UI/ModalConfirmation.vue';
import ImportEvent from '@/components/Calendar/admin/ImportEvent.vue';
import moment from 'moment';
export default {
    components: { AdminCalendarEventList, AddCalendarEvent, ModalConfirmation, ImportEvent },
    data() {
        return {
            data: [],
            totalPages: 10,
            currentPage: 1,
            limit: 10,
            totalCount: 20,
            deleteMessage: '',
            eventToDelete: null,
            year: moment().year(),
        };
    },
    mounted() {
        this.showCalendarList();
    },
    methods: {
        async showCalendarList(page = 1) {
            const { data } = await getCalendarEventList({
                page,
                limit: this.limit,
                filter: {
                    ...(this.year && {
                        date: {
                            $gte: moment(`01-01-${this.year}`).startOf('year').utc(),
                            $lte: moment(`01-01-${this.year}`).endOf('year').utc(),
                        },
                    }),
                },
            });
            this.data = data.data;
            this.page = page;
            this.totalPages = data.totalPages;
            this.currentPage = page;
            this.totalCount = data.totalCount;
        },
        changePage(page) {
            console.log('page: ', page);
            this.showCalendarList(page);
        },
        changeYear(year) {
            this.year = year;
            console.log('triggered year changed: ', year);
            this.page = 1;
            this.showCalendarList(this.page);
        },
        async refresh() {
            this.showCalendarList(this.page);
            this.$emit('refresh');
        },
        deleteEvent(data) {
            this.eventToDelete = data;
            this.deleteMessage = `Are you sure to delete ${data.content} event?`;
            this.$refs.deleteEventModal.openModal();
        },
        async onDeleteEvent() {
            try {
                await deleteEvent(this.eventToDelete);
                this.$Message.success(`${this.eventToDelete.content} event deleted`);
                this.refresh();
            } catch (err) {
                this.$Message.error(`Unable to delete ${this.eventToDelete.content} event: ${err.data.message}`);
                console.error(err);
            } finally {
                this.eventToDelete = null;
            }
        },
        openModal() {
            console.log('opening add event modal');
            this.$refs.calendarEventRequest.openModal();
        },
        openImportModal() {
            this.$refs.importEvent.openModal();
        },
        openModalForEditing(row) {
            console.log('opening edit event modal', row);
            this.$refs.calendarEventRequest.openModalForEditing(row);
        },
    },
};
</script>
