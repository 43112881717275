<template>
    <div class="admin-requests-wrapper">
        <Tabs class="pt-tabs" name="composer" :value="selectedTab" :animated="false">
            <TabPane label="Leave Request" name="plan" tab="composer">
                <LeaveRequest />
            </TabPane>
            <TabPane label="Leave Credits" name="wrapup" tab="composer">
                <LeaveCredit />
            </TabPane>
        </Tabs>
    </div>
</template>

<script>
import { getAdminPaginatedRequestsByUser, requestProcess, requestLeaveProcess } from '@/services/requests.services';
import { getUsers } from '@/services/users.services';
import moment from 'moment/moment';
import { checkIfFirstAttendancePeriod, endOfDate, startOfDate } from '/utility/helper';
import LeaveRequest from '@/components/Requests/Admin/Leave/LeaveRequest/index.vue';
import LeaveCredit from '@/components/Requests/Admin/Leave/LeaveCredit/index.vue';
import { adminCancelLeave } from '@/services/requests.services';

export default {
    components: { LeaveRequest, LeaveCredit },
    data() {
        return {
            isLoading: false,
            requestList: [],
            type: null,
            selectedStatus: this.$route.query.status,
            selectedType: null,
            selectedDates: null,
            selectedUser: this.$route.query.user,
            currentPage: 1,
            totalCount: null,
            isFilterClick: false,
            totalPerPage: 10,
            totalPages: 1,
            limit: 10,
            employeeList: [],
        };
    },
    created() {
        // if (!this.selectedDates) {
        //
        // }
        const actualDatePeriod = this.checkPayPeriod();
        this.selectedDates = {
            from: startOfDate(actualDatePeriod[0]),
            to: endOfDate(actualDatePeriod[1]),
        };
        if (this.$route.query.status) {
            this.handleOnStatusChanged(this.$route.query.status);
        }
        if (this.$route.query.user) {
            this.handleGetTimeDisputeRequestsByUser(this.$route.query.user, this.selectedDates);
        }
        if (this.selectedType) {
            this.handleGetAllTimeDisputesRequests(this.selectedDates, { type: this.selectedType });
        }
        this.handleGetAllEmployees();
    },

    methods: {
        onTypeChange(type) {
            this.selectedStatus = null;
            this.selectedUser = null;
            this.selectedType = type;
            this.type = { type };
            this.requestList = [];
            this.currentPage = 1;
            switch (type) {
                case 'timedispute':
                    this.handleGetAllTimeDisputesRequests(this.selectedDates, this.type);
                    break;
                case 'leave':
                    this.handleGetAllTimeDisputesRequests(this.selectedDates, this.type);
                    break;
                case 'loan':
                    break;
                case 'coerequest':
                    break;
                default:
                    break;
            }
        },
        async handleDateChange(date) {
            this.selectedDates = { from: startOfDate(date.from), to: endOfDate(date.to) };
            this.refresh();
            await this.handleGetAllTimeDisputesRequests(date, this.type);
        },
        checkPayPeriod() {
            const yearMonth = moment().format('yyyy-MM');
            console.log('checkIfDateFirstPeriod: ', checkIfFirstAttendancePeriod());
            const currentDate = moment().format('yyyy-MM-DD');
            return checkIfFirstAttendancePeriod()
                ? [`${yearMonth}-01`, `${yearMonth}-15`]
                : [`${yearMonth}-16`, moment(currentDate).endOf('month').format('yyyy-MM-DD')];
        },
        handleGetTimeDisputeRecordsByStatus(status, date, type = null) {
            this.isLoading = true;
            this.requestList = [];
            getAdminPaginatedRequestsByUser(
                {
                    status: status,
                    ...(this.selectedUser != null && { user: this.selectedUser }),
                    ...(date != null &&
                        this.selectedType.includes('timedispute') && {
                            requestedStart: { $gte: date.from, $lte: date.to },
                        }),
                    ...(date != null &&
                        this.selectedType.includes('leave') && { createdAt: { $gte: date.from, $lte: date.to } }),
                    ...(type && { ...type }),
                },
                this.currentPage,
                this.limit
            )
                .then((res) => {
                    console.log('Result by Status: ', res);
                    if (res.data.data.length) {
                        this.isLoading = true;
                        this.totalCount = res.data.totalCount;
                        this.requestList =
                            this.selectedUser != null
                                ? res.data.data.filter((i) => i.user._id == this.selectedUser && i.status == status)
                                : res.data.data.filter((i) => i.status == status);
                    } else {
                        this.requestList = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get requests data by status and user');
                    this.requestList = [];
                    this.isLoading = false;
                });
        },
        handleOnStatusChanged(status) {
            this.selectedStatus = status;

            this.refresh();
            if (!this.selectedDates) {
                const actualDatePeriod = this.checkPayPeriod();
                this.selectedDates = {
                    from: startOfDate(actualDatePeriod[0]),
                    to: endOfDate(actualDatePeriod[1]),
                };
            }

            if (status) {
                this.handleGetTimeDisputeRecordsByStatus(status, this.selectedDates, this.type);
            } else if (this.selectedUser) {
                this.handleGetTimeDisputeRequestsByUser(this.selectedUser, this.selectedDates, this.type);
            } else {
                this.handleGetAllTimeDisputesRequests(this.selectedDates, this.type);
            }
        },
        handleReload() {
            if (this.selectedStatus) {
                this.handleGetTimeDisputeRecordsByStatus(this.selectedStatus, this.selectedDates, this.type);
            } else if (this.selectedUser) {
                this.handleGetTimeDisputeRequestsByUser(this.selectedUser, this.selectedDates, this.type);
            } else {
                this.handleGetAllTimeDisputesRequests(this.selectedDates, this.type);
            }
        },
        handleGetTimeDisputeRequestsByUser(userId, date, type = null) {
            this.isLoading = true;
            this.requestList = [];
            getAdminPaginatedRequestsByUser(
                {
                    user: userId,
                    ...(this.selectedStatus != null && { status: this.selectedStatus }),
                    ...(date != null &&
                        this.selectedType.includes('timedispute') && {
                            requestedStart: { $gte: date.from, $lte: date.to },
                        }),
                    ...(date != null &&
                        this.selectedType.includes('leave') && { createdAt: { $gte: date.from, $lte: date.to } }),
                    ...(type && { ...type }),
                },
                this.currentPage,
                this.limit
            )
                .then((res) => {
                    console.log('Result by User: ', res);
                    if (res.data.data.length) {
                        this.totalCount = res.data.totalCount;
                        this.totalPages = res.data.totalPages;
                        this.totalPerPage = res?.data?.total || 5;
                        this.requestList =
                            this.selectedStatus != null
                                ? res.data.data.filter((i) => i.user._id == userId && i.status == this.selectedStatus)
                                : res.data.data.filter((i) => i.user._id == userId);
                    } else {
                        this.requestList = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get requests data by user');
                    this.requestList = [];
                    this.isLoading = false;
                });
        },
        handleGetAllEmployees() {
            this.isLoading = true;
            this.employeeList = [];
            getUsers()
                .then((res) => {
                    console.log('all-users: ', res);
                    if (res.data.length) {
                        let result = res.data.filter(
                            (x) => x.mainRole !== 'client' && x.mainRole !== 'admin' && x.isArchived === false
                        );

                        this.employeeList = result.map((i) => {
                            return {
                                value: i._id,
                                label: i.name,
                                name: i.name,
                                key: i._id,
                                _id: i._id,
                            };
                        });
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get users');
                    this.isLoading = false;
                });
        },
        handleGetAllTimeDisputesRequests(date, type = null) {
            this.isLoading = true;
            this.requestList = [];
            getAdminPaginatedRequestsByUser(
                {
                    ...(this.selectedUser != null && { user: this.selectedUser }),
                    ...(this.selectedStatus != null && { status: this.selectedStatus }),
                    ...(date != null &&
                        this.selectedType.includes('timedispute') && {
                            requestedStart: { $gte: date.from, $lte: date.to },
                        }),
                    ...(date != null &&
                        this.selectedType.includes('leave') && { createdAt: { $gte: date.from, $lte: date.to } }),
                    ...(type && { ...type }),
                },
                this.currentPage,
                this.limit
            )
                .then((res) => {
                    console.log('Result by All: ', res);
                    if (res.data.data.length) {
                        this.totalCount = res.data.totalCount;
                        this.totalPerPage = res?.data?.total || 5;
                        this.totalPages = res.data.totalPages;
                        this.requestList =
                            this.selectedUser != null && this.selectedStatus != null
                                ? res.data.data.filter(
                                      (i) => i.user._id == this.selectedUser && i.status == this.selectedStatus
                                  )
                                : res.data.data;
                    } else {
                        this.requestList = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get all requests data');
                    this.requestList = [];
                    this.isLoading = false;
                });
        },
        refresh() {
            this.currentPage = 1;
            this.totalPages = 1;
            this.totalCount = 1;
        },
        handleOnEmployeeChanged(user) {
            this.selectedUser = user;
            this.refresh();
            if (!this.selectedDates) {
                const actualDatePeriod = this.checkPayPeriod();
                this.selectedDates = {
                    from: startOfDate(actualDatePeriod[0]),
                    to: endOfDate(actualDatePeriod[1]),
                };
            }

            if (user) {
                this.handleGetTimeDisputeRequestsByUser(user, this.selectedDates, this.type);
            } else if (this.selectedStatus) {
                this.handleGetTimeDisputeRecordsByStatus(this.selectedStatus, this.selectedDates, this.type);
            } else {
                this.handleGetAllTimeDisputesRequests(this.selectedDates, this.type);
            }
        },
        handleRequest(id, status) {
            this.isLoading = true;
            const data = {
                id: id,
                status: status,
            };
            requestProcess(data)
                .then((res) => {
                    if (res && res.data.success) {
                        this.$Message.success(`Request has been ${status}`);
                        this.isLoading = false;
                        this.handleReload();
                        this.showModal = false;
                    }
                })
                .catch((err) => {
                    if (err.data.statusCode === 400 && err.data.message === 'request not found') {
                        this.$Message.error('Request not found');
                    } else if (err.data.statusCode === 403 && err.data.message === 'request already processed') {
                        this.$Message.error(
                            'You`ve already processed this request time dispute. Please do check it again.'
                        );
                    } else {
                        this.$Message.error('Failed to process request time dispute');
                    }
                    this.isLoading = false;
                });
        },

        handleLeaveRequest(id, status) {
            this.isLoading = true;
            const data = {
                id: id,
                status: status,
            };
            if (status === 'cancel') {
                adminCancelLeave(id)
                    .then(() => {
                        this.$Message.success(`Request has been cancelled`);
                        this.isLoading = false;
                        this.handleReload();
                        this.showModal = false;
                    })
                    .catch((err) => {
                        console.log('err: ', err);
                        if (err.data.statusCode === 400 && err.data.message === 'request not found') {
                            this.$Message.error('Request not found');
                        } else if (err.data.statusCode === 403 && err.data.message === 'request already cancelled') {
                            this.$Message.error(
                                'You`ve already processed this request time dispute. Please do check it again.'
                            );
                        } else {
                            this.$Message.error('Failed to process request cancel leave');
                        }
                        this.isLoading = false;
                    });
            } else {
                requestLeaveProcess(data)
                    .then((res) => {
                        if (res && res.data.success) {
                            this.$Message.success(`Request has been ${status}`);
                            this.isLoading = false;
                            this.handleReload();
                            this.showModal = false;
                        }
                    })
                    .catch((err) => {
                        if (err.data.statusCode === 400 && err.data.message === 'request not found') {
                            this.$Message.error('Request not found');
                        } else if (err.data.statusCode === 403 && err.data.message === 'request already processed') {
                            this.$Message.error(
                                'You`ve already processed this request time dispute. Please do check it again.'
                            );
                        } else {
                            this.$Message.error('Failed to process request time dispute');
                        }
                        this.isLoading = false;
                    });
            }
        },
        handleOnPageChanged(page) {
            this.currentPage = page;
            if (this.selectedStatus) {
                this.handleGetTimeDisputeRecordsByStatus(this.selectedStatus, this.selectedDates, this.type);
            } else if (this.selectedUser) {
                this.handleGetTimeDisputeRequestsByUser(this.selectedUser, this.selectedDates, this.type);
            } else {
                this.handleGetAllTimeDisputesRequests(this.selectedDates, this.type);
            }
        },
    },
};
</script>
<style lang="scss">
.admin-requests-wrapper {
    .ivu-col {
        button {
            border-radius: 15px;
        }
    }
    .title-wrapper {
        display: block;

        .ivu-col {
            max-width: 100% !important;
        }
        .text-right {
            text-align: left;
            padding-top: 0 !important;
        }
    }
}
</style>
