<template>
    <div class="users-table-list fullwidth">
        <Row type="flex" justify="space-between" class="code-row-bg title-wrapper" style="margin-bottom: 20px">
            <Col span="6">
                <Card class="page-title no-padding" dis-hover :bordered="false">
                    {{ formTitle }}
                </Card>
            </Col>
            <Col span="6" class="text-right">
                <Button class="pt-btn-2" style="margin-right: 10px" @click="$router.go(-1)"> Cancel </Button>
                <Button class="pt-btn-1" :loading="isLoading" @click="handleSubmitForm"> Save </Button>
            </Col>
        </Row>

        <Card class="section-title"> USER DATA </Card>
        <Form ref="form" :model="form" :rules="ruleInline" label-position="left" inline>
            <Row>
                <Col span="12">
                    <FormItem label="Full name" prop="name">
                        <Input v-model="form.name" />
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="Main role" prop="mainRole">
                        <Select v-model="form.mainRole">
                            <Option v-for="(mainRole, idx) in mainRole" :key="idx" :value="mainRole.value">
                                {{ mainRole.name }}
                            </Option>
                        </Select>
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span="12">
                    <FormItem label="Email" prop="email">
                        <Input v-model="form.email" />
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="Password" prop="password">
                        <Input v-model="form.password" type="password" />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <i-col span="12">
                    <FormItem label="Color Legend" prop="legendColor">
                        <ColorPicker v-model="form.legendColor" />
                    </FormItem>
                </i-col>
                <i-col span="12">
                    <FormItem label="Work Anniversary" prop="legendColor">
                        <DatePicker v-model="form.work_anniversary" />
                    </FormItem>
                </i-col>
            </Row>
            <Row>
                <Col span="12">
                    <FormItem label="Archive user" class="activeSwitch">
                        <i-switch
                            v-model="form.isArchived"
                            class="switchColor"
                            false-color="#13ce66"
                            true-color="#ff4949"
                            @on-change="onStatusChange(form.isArchived)"
                        />
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="Birth Date" prop="legendColor">
                        <DatePicker v-model="form.birth_date" />
                    </FormItem>
                </Col>
            </Row>

            <Row>
                <Col span="12">
                    <FormItem label="Blockchain Wallet" prop="blockchainWalletAddress">
                        <Input v-model="form.blockchainWalletAddress" />
                    </FormItem>
                </Col>
            </Row>
            <Row class="md-margin-bottom">
                <Col span="12">
                    <ConnectWallet class="md-margin-left" @handleSetWalletAddress="handleSetWalletAddress" />
                </Col>
            </Row>

            <hr />
            <Row>
                <Col span="12">
                    <FormItem label="Third-party Apps" />
                </Col>
            </Row>
            <Row>
                <Col span="12">
                    <FormItem label="Asana">
                        <Input v-model="form.asanaId" />
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="Slack">
                        <Input v-model="form.slackId" />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span="12">
                    <FormItem label="Trello">
                        <Input v-model="form.trelloId" />
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="Bitbucket">
                        <Input v-model="form.bitbucketId" />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span="12">
                    <FormItem label="Jira">
                        <Input v-model="form.jiraId" />
                    </FormItem>
                </Col>
            </Row>
        </Form>
    </div>
</template>
<script>
import { getUsers, postUsers, getUsersById, updateUsersById, archiveUser } from '@/services/users.services';
import '../../style.css';
import ConnectWallet from './ConnectWallet';
import moment from 'moment';

export default {
    components: {
        ConnectWallet,
    },
    data() {
        return {
            formTitle: this.$route.query.id ? 'EDIT TEAM MEMBER' : 'ADD NEW TEAM MEMBER',
            form: {
                name: '',
                mainRole: '',
                email: '',
                password: '',
                birth_date: '',
                work_anniversary: '',
                legendColor: this.getRandomColor(),
                jiraId: null,
                bitbucketId: null,
                isArchived: false,
                blockchainWalletAddress: null,
            },
            ruleInline: {
                name: [
                    {
                        required: true,
                        message: 'Please fill in your full name',
                        trigger: 'blur',
                    },
                ],
                mainRole: [
                    {
                        required: true,
                        message: 'Please fill in your role.',
                        trigger: 'change',
                    },
                ],
                email: [
                    {
                        required: true,
                        message: 'Please fill in your email address',
                        trigger: 'blur',
                    },
                    { type: 'email', message: 'Incorrect email format', trigger: 'blur' },
                ],
                password: [
                    {
                        required: true,
                        message: 'Please fill in the password.',
                        trigger: 'blur',
                    },
                    {
                        type: 'string',
                        min: 6,
                        message: 'The password length cannot be less than 6 bits',
                        trigger: 'blur',
                    },
                ],
            },
            date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
            valid: true,
            isLoading: false,
        };
    },
    mounted() {
        getUsers()
            .then((res) => {
                this.usersData = res.data;
            })
            .catch((err) => {
                console.log('Failed to fetch data', err);
            });
        this.setFormDataValues();
    },
    methods: {
        getRandomColor() {
            let letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },

        async setFormDataValues() {
            if (this.$route.query.id) {
                this.form = await this.handleGetUsersById(this.$route.query.id);
            }
        },
        async handleGetUsersById(id) {
            return await getUsersById(id)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    console.log('Failed to fetch data', err);
                });
        },

        async handleSubmitForm() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitForm();
                } else {
                    this.$Message.error('Fill in the required fields');
                }
            });
        },

        async submitForm() {
            this.form = {
                ...this.form,
                isActive: !this.form.isArchived,
            };
            console.log('new Date(this.form.birth_date).getUTCDate(): ', new Date(this.form.birth_date));
            if (this.form._id) {
                await updateUsersById(this.form._id, {
                    ...this.form,
                    ...(this.form.work_anniversary && {
                        work_anniversary: moment(this.form.work_anniversary).format('YYYY-MM-DD'),
                    }),
                    ...(this.form.birth_date && { birth_date: moment(this.form.birth_date).format('YYYY-MM-DD') }),
                })
                    .then(() => {
                        this.$Message.success('Sucessfully update user');
                        this.isLoading = true;
                        this.$refs.form.resetFields();
                        this.$router.push({ name: 'Users' });
                    })
                    .catch((err) => {
                        if (err.data.statusCode === 400 && err.data.message === 'Email already exist') {
                            this.$Message.error('Email already exist');
                        } else {
                            this.$Message.error('Failed to update user');
                        }
                        this.isLoading = false;
                    });
            } else {
                await postUsers(this.form)
                    .then(() => {
                        this.$Message.success('Sucessfully add user');
                        this.isLoading = true;
                        this.$refs.form.resetFields();
                        this.$router.push({ name: 'Users' });
                    })
                    .catch((err) => {
                        if (err.data.statusCode === 400 && err.data.message === 'Email already exist') {
                            this.$Message.error('Email already exist');
                        } else {
                            this.$Message.error('Failed to add user');
                        }
                        this.isLoading = false;
                    });
            }
        },

        onStatusChange(status) {
            const id = this.$route.query.id;
            archiveUser(id, { isArchived: status })
                .then(() => {
                    this.$Message.success('User status successfully updated');
                })
                .catch(() => {
                    this.$Message.error('Failed to update user status');
                });
        },

        async handleSetWalletAddress(data) {
            this.isLoading = true;
            const payload = {
                ...this.form,
                isActive: !this.form.isArchived,
                blockchainWalletAddress: data.address,
            };
            await updateUsersById(this.form._id, payload)
                .then(() => {
                    const action = data.disconnect ? 'unlinked' : 'linked';
                    this.$Message.success(`Successfully ${action} your blockchain wallet`);
                    this.isLoading = false;
                    this.form = payload;
                })
                .catch(() => {
                    this.isLoading = false;
                    this.$Message.error('Failed to update user blockchain wallet');
                });
        },
    },
};
</script>
<style lang="scss">
.switchColor.ivu-switch-checked {
    border-color: #44e53e;
    background-color: #44e53e;
}
.activeSwitch .ivu-form-item-content {
    display: block !important;
}

@media screen and (max-width: 767px) {
    .users-table-list {
        .title-wrapper {
            display: block;

            .ivu-col {
                max-width: 100%;
            }
            .text-right {
                text-align: left;
            }
        }
        form {
            .ivu-row {
                display: block;

                .ivu-col-span-12 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
    }
}
</style>
