<template>
    <div>
        <Modal v-model="showModal" title="Confirmation" no-footer>
            <p class="text-center">{{ this.message }}?</p>
            <div slot="footer">
                <Button class="pt-btn-2" @click="handleCancel"> CANCEL </Button>
                <Button class="pt-btn-3" @click="handleConfirm"> OK </Button>
            </div>
        </Modal>
    </div>
</template>
<script>
export default {
    props: {
        message: {
            type: String,
            default: 'Are you sure?',
        },
    },
    data() {
        return {
            showModal: false,
        };
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        handleCancel() {
            this.showModal = false;
        },
        handleConfirm() {
            this.$emit('ok');
            this.showModal = false;
        },
    },
};
</script>
