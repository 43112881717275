<template>
    <div>
        <div>
            <Select v-model="yearSelected" style="width: 200px" @on-change="changeYear">
                <Option v-for="item in getYears" :key="item" :value="item">{{ item }}</Option>
            </Select>
            <Table :columns="tblColumns" :data="tblData" size="small" :loading="isLoading" class="adminRequestsList" />
        </div>
        <Page
            :total="totalCount"
            :current="currentPage"
            :page-size="totalPerPage"
            class="pagination"
            @on-change="handleOnpageChanged"
        />
        <ViewRequestModal ref="ViewRequest" @create="handleReloadList" />
    </div>
</template>

<script>
import moment from 'moment';
import ViewRequestModal from '@/components/Requests/Dev/Leave/LeaveRequest/ViewRequestModal.vue';
export default {
    components: { ViewRequestModal },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        requestsDataList: {
            type: Array,
            default: () => [],
        },
        yearSelected: {
            type: Number,
            default: () => moment().year(),
        },
        currentPage: {
            type: Number,
            default: 1,
        },
        totalCount: {
            type: Number,
            default: 0,
        },
        totalPages: {
            type: Number,
            default: 1,
        },
        totalPerPage: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        getYears() {
            return Array.from({ length: 6 }, (value, index) => index + moment().year() - 3);
        },
    },
    data() {
        return {
            tblColumns: [
                {
                    title: 'Date',
                    key: 'date',
                    render: (h, params) => {
                        return h('div', params.row.date ? moment(params.row.date).utc().format('YYYY-MM-DD') : '');
                    },
                },
                {
                    title: 'type',
                    key: 'type',
                    render: (h, params) => {
                        return h('div', params.row.type);
                    },
                },
                {
                    title: 'name',
                    key: 'content',
                    //fixed: 'left',
                    render: (h, params) => {
                        return h('div', params.row.content);
                    },
                },
                {
                    title: 'Actions',
                    minWidth: 200,
                    align: 'left',
                    render: (h, params) => {
                        return h('div', [
                            h('Tooltip', { props: { placement: 'top', content: 'View' } }, [
                                h(
                                    'Button',
                                    {
                                        class: 'pt-btn-3',
                                    },
                                    [
                                        h('Icon', {
                                            props: {
                                                type: 'md-create',
                                                size: '25',
                                            },
                                            on: {
                                                click: () => {
                                                    // console.log('params: ', params);
                                                    // this.$refs.ViewRequest.openModalForAdmin(params.row);
                                                    console.log(params.row);
                                                    this.$emit('edit-event', params.row);
                                                },
                                            },
                                        }),
                                    ],
                                    'view'
                                ),
                            ]),
                            h('Tooltip', { props: { placement: 'top', content: 'Reject' } }, [
                                h(
                                    'Button',
                                    {
                                        class: `pt-btn-1`,
                                    },
                                    [
                                        h('Icon', {
                                            props: {
                                                type: 'ios-trash',
                                                size: '25',
                                            },
                                            on: {
                                                click: () => {
                                                    this.handleDeleteRequest(params.row);
                                                    console.log(params.row);
                                                },
                                            },
                                        }),
                                    ],
                                    'reject'
                                ),
                            ]),
                        ]);
                    },
                },
            ],
            tblData: [],
        };
    },

    watch: {
        requestsDataList(data) {
            this.tblData = data;
        },
        totalPerPage(total) {
            const arrTotal = [];
            arrTotal.push(total);
        },
    },
    created() {
        if (this.requestsDataList) {
            this.tblData = this.requestsDataList;
        }
    },
    methods: {
        handleReloadList() {
            this.$emit('reload');
        },
        changeYear(year) {
            console.log('year: ', year);
            this.$emit('changeYear', year);
        },
        handleOnpageChanged(page) {
            console.log('handleOnpageChanged', page);
            this.$emit('on-page-change', page);
        },
        handleProcessRequest(id, status) {
            console.log('handleProcessRequest status: ', status);
            this.$emit('on-process-request', id, status);
        },
        handleDeleteRequest(data) {
            this.$emit('on-delete-request', data);
        },
    },
};
</script>

<style lang="scss">
ul.pagination.ivu-page {
    text-align: end;
}
.adminRequestsList button {
    width: 25px;
    margin-left: 10px;
}
.adminRequestsList i.ivu-icon {
    margin-left: -12.5px;
}
.btn-hide {
    display: none;
}
.statusLabel {
    display: inline-block;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
    vertical-align: middle;
    max-width: 250px;
    padding: 5px;
    text-align: center;
}
.success {
    background: #49e97e;
    font-weight: bold;
}
.warning {
    background: #ffc107;
    font-weight: bold;
}
.danger {
    background: #e9495d;
    font-weight: bold;
}
.primary {
    background: #211841;
}
.secondary {
    background: #828899;
}
</style>
