<template>
    <div class="text-center section">
        <h2 class="h2">Calendar</h2>
        <vc-calendar
            class="custom-calendar max-w-full"
            :masks="masks"
            :attributes="attributes"
            disable-page-swipe
            is-expanded
        >
            <template #day-content="{ day, attributes }">
                <span class="day-label text-sm text-gray-900"
                    >{{ day.day }}
                    <!--                    <Button type="dashed" size="small" @click="addCalendarEvent({attributes,day})">-->
                    <!--                        <Icon type="md-add"></Icon> </Button>-->
                </span>
                <div class="day-content" style="height: 90px; overflow-y: scroll; width: 160px; overflow-x: scroll">
                    <div class="flex flex-col h-full z-10">
                        <div>
                            <p
                                v-for="attr in attributes"
                                :key="attr.key"
                                class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                                :class="attr.customData.class"
                                :style="attr.customData.style + 'border-radius: 3px;'"
                            >
                                {{ attr.customData.title }}
                            </p>
                        </div>
                    </div>
                </div>
            </template>
        </vc-calendar>
    </div>
</template>

<script>
import {
    getCalendarAttendanceList,
    getCalendarBdaysAndAnniversaries,
    getCalendarEventList,
} from '@/services/calendar-events.services';
import moment from 'moment';

export default {
    data() {
        return {
            masks: {
                weekdays: 'WWW',
            },
            attributes: [],
        };
    },
    async created() {
        await this.showCalendarList();
    },
    methods: {
        async showCalendarList() {
            try {
                const {
                    data: { data },
                } = await getCalendarEventList({ limit: 200 });
                const transformedData = this.transformRawEventToCalendarData(data);
                const attendanceData = await getCalendarAttendanceList(moment().endOf('month').format('yyyy-MM-DD'));
                const transformedLeaveData = this.transformRawLeavesToCalendarData(attendanceData.data);
                this.attributes.push(...transformedData, ...transformedLeaveData);
                const annivsAndBdays = await this.generateAnniversariesAndBdays();
                this.attributes.push(...annivsAndBdays);
            } catch (err) {
                console.log('error showCalendarList: ', err);
            }
        },
        async refresh() {
            this.attributes = [];
            await this.showCalendarList();
        },
        async generateAnniversariesAndBdays() {
            let {
                data: { anniversaries, bdays },
            } = await getCalendarBdaysAndAnniversaries();
            anniversaries = anniversaries.map((elem) => {
                elem.dates = new Date(elem.customData.date);
                return elem;
            });
            bdays = bdays.map((elem) => {
                elem.dates = new Date(elem.customData.date);
                return elem;
            });
            let annivBdays = [...anniversaries, ...bdays];

            annivBdays = this.transformRawBdaysAndAnniversariesToCalendarData(annivBdays);
            return annivBdays;
        },
        transformRawEventToCalendarData(data) {
            return data.map((elem) => {
                return {
                    key: elem._id,
                    customData: {
                        title: elem.content,
                        class: 'bg-orange-500 text-white',
                        ...(elem.type === 'Regular-Holiday' && {
                            style: 'background-color: #004000 ; color: white; margin: 5px;',
                        }),
                        ...(elem.type === 'Special-Non-Working-Holiday' && {
                            style: 'background-color: #008000 ; padding: 0 15px; color: white; margin: 5px;',
                        }),
                        ...(elem.type === 'Special-Working-Holiday' && {
                            style: 'background-color: #00c000 ; color: white; margin: 5px;',
                        }),
                    },
                    dates: new Date(elem.date),
                };
            });
        },
        transformRawLeavesToCalendarData(data) {
            return data.map((elem) => {
                return {
                    ...elem,
                    customData: {
                        ...elem.customData,
                        style: 'padding: 0 15px; border: #766d9d; border-color: #5b6270; background-color: #211841; color: white; margin: 5px;',
                    },
                };
            });
        },
        transformRawBdaysAndAnniversariesToCalendarData(data) {
            return data.map((elem) => {
                return {
                    ...elem,
                    customData: {
                        ...elem.customData,
                        class: 'bg-orange-500 text-white',
                        ...(elem.customData.type === 'birth_day' && {
                            style: 'border-radius: 3px;margin: 5px;border: double; border-color: #1418ff; background-color: #270459; color: white;',
                        }),
                        ...(elem.customData.type === 'anniversary' && {
                            style: 'border-radius: 3px; margin: 5px; border: #766d9d; border-color: #991b12; background-color: #a8301b; color: white;',
                        }),
                    },
                };
            });
        },
    },
};
</script>

<style lang="postcss" scoped>
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/deep/ .custom-calendar.vc-container {
    --day-border: 1px solid #b8c2cc;
    --day-border-highlight: 1px solid #b8c2cc;
    --day-width: 90px;
    --day-height: 90px;
    --weekday-bg: #f8fafc;
    --weekday-border: 1px solid #eaeaea;

    border-radius: 0;
    width: 100%;
    & .vc-header {
        background-color: #f1f5f8;
        padding: 10px 0;
    }
    & .vc-weeks {
        padding: 0;
    }
    & .vc-weekday {
        background-color: var(--weekday-bg);
        border-bottom: var(--weekday-border);
        border-top: var(--weekday-border);
        padding: 5px 0;
    }
    & .vc-day {
        padding: 0 5px 3px 5px;
        text-align: left;
        height: var(--day-height);
        min-width: var(--day-width);
        background-color: white;
        &.weekday-1,
        &.weekday-7 {
            background-color: #eff8ff;
        }
        &:not(.on-bottom) {
            border-bottom: var(--day-border);
            &.weekday-1 {
                border-bottom: var(--day-border-highlight);
            }
        }
        &:not(.on-right) {
            border-right: var(--day-border);
        }
    }
    .day-content {
        overflow-y: scroll;
    }
    & .vc-day-dots {
        margin-bottom: 5px;
    }
}
</style>
