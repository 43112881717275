<script setup>
import AdminCalendar from '@/components/Calendar/admin/AdminCalendar.vue';
import AdminCalendarEvent from '@/views/AdminCalendarEvent.vue';
export default {
    components: { AdminCalendar, AdminCalendarEvent },
};
</script>

<template>
    <div>
        <Tabs type="card">
            <TabPane label="Calendar"><AdminCalendar ref="calendar" /></TabPane>
            <TabPane label="Events"><AdminCalendarEvent @refresh="$refs.calendar.refresh()" /></TabPane>
        </Tabs>
    </div>
</template>

<style scoped lang="scss"></style>
